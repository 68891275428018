<template>
  <div>
    <el-table
      :data="data"
      class="table-normal animate__animated animate__fadeIn animate__slow w-100"
      :class="data.length === 0 ? 'empty' : ''"
      v-loading="loading"
      element-loading-text="กำลังโหลดข้อมูล..."
      element-loading-spinner="el-icon-loading"
    >
      <template slot="empty">
        <img alt="logo" src="@/assets/logo.png" />
        <p>ไม่พบข้อมูล</p>
      </template>
      <el-table-column
        width="80"
        align="center"
        type="index"
        :index="indexMethod"
      >
        <template slot="header">
          <div class="two-line">
            <p>No.</p>
            <p>(ลำดับ)</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column min-width="250" align="center" prop="companyName">
        <template slot="header">
          <div class="two-line">
            <p>Company name</p>
            <p>(ชื่อบริษัท)</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column width="140" align="center" prop="juristicId">
        <template slot="header">
          <div class="two-line">
            <p>ID</p>
            <p>(เลขนิติบุคคล)</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        min-width="200"
        prop="address"
        :show-overflow-tooltip="true"
      >
        <template slot="header">
          <div class="two-line">
            <p>Address Of Client</p>
            <p>(ที่อยู่)</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column min-width="180" align="center" prop="contactPerson">
        <template slot="header">
          <div class="two-line">
            <p>Contact person name</p>
            <p>(ชื่อผู้ติดต่อ)</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column min-width="150" align="center" prop="phone">
        <template slot="header">
          <div class="two-line">
            <p>Phone number</p>
            <p>(เบอร์โทร)</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column min-width="200" align="center" prop="email">
        <template slot="header">
          <div class="two-line">
            <p>E-mail</p>
            <p>(อีเมล)</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column min-width="150" align="center" prop="type">
        <template slot="header">
          <div class="two-line">
            <p>Type</p>
            <p>(ประเภท)</p>
          </div>
        </template>
        <template slot-scope="scope">
          <span v-if="scope.row.isFood" class="color-main-1">Food safety</span>
          {{ scope.row.isFood && scope.row.isISO ? ", " : "" }}
          <span v-if="scope.row.isISO" class="color-error">ISO</span>
        </template>
      </el-table-column>
      <el-table-column min-width="150" align="center" prop="company">
        <template slot="header">
          <div class="two-line">
            <p>Company</p>
            <p>(บริษัท)</p>
          </div>
        </template>
        <template slot-scope="scope">
          <span
            v-for="(company, indexS) in scope.row.companyType"
            :key="indexS"
          >
            <span v-if="company.type == 2" class="color-error">
              {{ mapDataOption(company.company, optionsCompanyIso, "company") }}
            </span>
            <span
              v-else-if="scope.row.companyType.length == 1 && company.type == 1"
              >-</span
            >
            {{
              indexS != scope.row.companyType.length - 1 && company.type == 2
                ? ", "
                : ""
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column min-width="150" align="center" prop="standard">
        <template slot="header">
          <div class="two-line">
            <p>Standard</p>
            <p>(มารตฐาน)</p>
          </div>
        </template>
        <template slot-scope="scope">
          <span
            v-for="(company, indexS) in scope.row.companyType"
            :key="indexS"
          >
            <span v-if="company.type == 1" class="color-main-1">
              {{
                mapDataOption(company.standard, optionsStandardFood, "standard")
              }}</span
            >
            <span v-else-if="company.type == 2" class="color-error">
              {{
                mapDataOption(
                  company.standard,
                  company.company == 1
                    ? optionsStandardIsoOne
                    : optionsStandardIsoTwo,
                  "standard"
                )
              }}
              {{ company.standard == 99 ? `(${company.other})` : "" }}
            </span>

            {{ indexS != scope.row.companyType.length - 1 ? ", " : "" }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        min-width="150"
        align="center"
        label="CAT No."
        prop="catNo"
      >
        <template slot-scope="scope">
          <span
            v-for="(company, indexS) in scope.row.companyType"
            :key="indexS"
          >
            <span :class="company.type == 1 ? 'color-main-1' : 'color-error'">{{
              company.catNo.join(" ")
            }}</span>
            {{ indexS != scope.row.companyType.length - 1 ? ", " : "" }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        min-width="400"
        prop="scope"
        :show-overflow-tooltip="true"
        align="left"
      >
        <template slot="header">
          <div class="two-line">
            <p>Scope</p>
            <p>(ขอบข่ายการรับรอง)</p>
          </div>
        </template>
        <template slot-scope="scope">
          <span
            v-for="(company, indexS) in scope.row.companyType"
            :key="indexS"
          >
            <span :class="company.type == 1 ? 'color-main-1' : 'color-error'">
              {{ company.scoped }}</span
            >

            {{ indexS != scope.row.companyType.length - 1 ? ", " : "" }}
          </span>
        </template>
      </el-table-column>
      <el-table-column min-width="150" align="center" prop="auditCycle">
        <template slot="header">
          <div class="two-line">
            <p>Audit cycle</p>
            <p>(รอบการตรวจติดตาม)</p>
          </div>
        </template>
        <template slot-scope="scope">
          <span
            v-for="(company, indexS) in scope.row.companyType"
            :key="indexS"
          >
            <span :class="company.type == 1 ? 'color-main-1' : 'color-error'">
              {{ company.auditCycle }} เดือน</span
            >

            {{ indexS != scope.row.companyType.length - 1 ? ", " : "" }}
          </span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      optionsStandardFood: (state) => state.optionAll.optionsStandardFood,
      optionsCompanyIso: (state) => state.optionAll.optionsCompanyIso,
      optionsStandardIsoOne: (state) => state.optionAll.optionsStandardIsoOne,
      optionsStandardIsoTwo: (state) => state.optionAll.optionsStandardIsoTwo,
    }),
  },
  props: {
    data: {
      default: Array,
      return: [],
    },
    loading: {
      type: Boolean,
      return: false,
    },
    page: {
      type: Number,
      return: 0,
    },
    typePage: {
      type: String,
      return: "",
    },
    limit: {
      type: Number,
      return: 0,
    },
  },
  methods: {
    indexMethod(index) {
      return this.page * this.limit - this.limit + index + 1;
    },
  },
};
</script>
