<template>
  <DefaultTemplate
    :footer="true"
    v-loading.lock="loading"
    element-loading-text="กำลังโหลดข้อมูล..."
    element-loading-spinner="el-icon-loading"
    id="master-data"
  >
    <div>
      <div class="is-flex ai-center js-between flex-wrap">
        <h2 class="mg-y-2 text-capitalize">Master Data Clients</h2>

        <el-button
          v-if="user.role == 80"
          round
          @click="exportMasterData()"
          :loading="loadingExport"
          type="warning"
          class="button-radius mg-t-2 font-14"
          >Export</el-button
        >
      </div>
      <el-row
        :gutter="15"
        class="mg-t-5 custom-filter"
        :class="user.role == 80 ? 'board' : ''"
      >
        <el-form ref="formFilter" :model="filter" label-position="top">
          <FilterMasterData
            :filter="filter"
            :typePage="typePage"
            :typeMaster="typeMaster"
            :role="user.role"
            :optionsCompany="optionsCompany"
            :optionsStandard="optionsStandard"
            :optionsStatus="optionsStatus"
            :optionsAuditCycle="optionsAuditCycle"
            @selectOptionType="selectOptionType"
            @selectOptionCompany="selectOptionCompany"
            @searchMasterData="searchFilter"
          />
        </el-form>
      </el-row>
      <el-row :gutter="15" class="mg-b-5">
        <el-col :span="24">
          <TableMasterDataClient
            :data="fetchData"
            :loading="loadingTable"
            :page="page"
            :limit="limit"
          />
          <p class="text-right font-12 color-69">Total : {{ total }}</p>

          <div class="text-center mg-y-4">
            <el-pagination
              :hide-on-single-page="true"
              @size-change="handleSizeChange"
              @current-change="changePage"
              :current-page.sync="page"
              :page-size="limit"
              layout="prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </el-col>
      </el-row>
    </div>
  </DefaultTemplate>
</template>

<script>
import "@/mixin/DefaultTemplate";
import { HTTP } from "@/service/axios";
import FilterMasterData from "@/components/filter/FilterMasterData";
import TableMasterDataClient from "@/components/table/TableMasterDataClient";

import { mapState } from "vuex";

export default {
  components: {
    FilterMasterData,
    TableMasterDataClient,
  },
  computed: {
    ...mapState({
      _token: (state) => state._token,
      user: (state) => state.user,
      optionsAuditCycle: (state) => state.optionAll.auditCycle,
      optionsStatus: (state) => state.optionAll.status,
      typeMaster: (state) => state.typeMaster,
    }),
  },
  mounted() {
    this.getMasterData();
  },
  data() {
    return {
      typePage: "clients",
      loading: true,
      loadingTable: false,
      loadingExport: false,
      filter: {
        search: "",
        auditCycle: "",
        companyType: "",
        company: "",
        standard: "",
      },
      fetchData: [],
      optionsType: [],
      optionsCompany: [],
      optionsStandard: [],
      page: 1,
      skip: 0,
      limit: 10,
      total: 20,
      fileUrl: process.env.VUE_APP_BASE_URL_FILE,
    };
  },
  methods: {
    async getMasterData() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;

      let obj = {
        ...this.filter,
        skip: this.skip,
        limit: this.limit,
      };
      HTTP.post(`certification/requisition`, obj)
        .then(async (res) => {
          if (res.data.success) {
            this.fetchData = res.data.obj;
            let setData = [];
            res.data.obj.forEach(async (row) => {
              let setobj = {
                ...row,
                companyType: row.companyType.sort((a, b) => {
                  let modifier = 1;
                  if (a.type < b.type) return -1 * modifier;
                  if (a.type > b.type) return 1 * modifier;
                  return 0;
                }),
                isFood:
                  row.companyType.find((row) => row.type == 1) != undefined
                    ? true
                    : false,
                isISO:
                  row.companyType.find((row) => row.type == 2) != undefined
                    ? true
                    : false,
              };
              setData.push(setobj);
            });
            this.fetchData = setData;
            this.total = res.data.total;
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.data.status == 401) {
              this.checkAuth();
            }
          }
        })
        .finally(() => {
          this.loading = false;
          this.loadingTable = false;
        });
    },
    handleSizeChange(val) {
      this.limit = val;
      this.changePage(1);
    },
    changePage(page) {
      this.loadingTable = true;
      this.pageNow = page;
      this.skip = page * this.limit - this.limit;
      this.getMasterData();
    },
    searchFilter() {
      this.loadingTable = true;
      if (this.filter.search == "") {
        this.skip = 0;
        this.limit = 10;
      }
      this.getMasterData();
    },
    selectOptionType(valType) {
      this.optionsStandard = [];
      this.optionsCompany = [];
      const findByType = this.typeMaster.find((f) => f.typeNum == valType);

      if (valType == 1) {
        this.optionsStandard = findByType.standard;
        this.filter.company = "";
      } else if (valType == 2) {
        this.optionsCompany = findByType.company;
        this.filter.standard = "";
      } else {
        this.filter.company = "";
        this.filter.standard = "";
      }
      this.searchFilter();
    },
    selectOptionCompany(valCompany) {
      this.optionsStandard = [];
      this.filter.standard = "";

      if (valCompany) {
        const findByCompany = this.optionsCompany.find(
          (f) => f.companyNum == valCompany
        );
        this.optionsStandard = findByCompany.standard;
      } else {
        this.filter.company = "";
        this.filter.standard = "";
      }
      this.searchFilter();
    },
    exportMasterData() {
      this.loadingExport = true;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      delete HTTP.defaults.headers.common["X-CSRF-TOKEN"]

      HTTP.get(`master/data/export/clients`)
        .then((res) => {
          if (res.data.success) {
            var file_path = this.fileUrl + res.data.filename;
            var a = document.createElement("A");
            a.href = file_path;
            a.download = file_path.substr(file_path.lastIndexOf("/") + 1);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            this.$notify({
              title: "Export Master Data Clients สำเร็จ!",
              type: "success",
              customClass: "success",
            });
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.data.status == 401) {
              this.checkAuth();
            }
          }
        })
        .finally(() => {
          this.loadingExport = false;
        });
    },
  },
};
</script>
