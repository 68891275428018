<template>
  <div>
    <el-col :span="24" :sm="12" :md="6" :lg="6">
      <el-form-item label="Search">
        <el-input
          clearable
          placeholder="Search"
          v-model="filter.search"
          class="search"
          @input="$emit('searchMasterData')"
        >
          <i slot="prefix" class="fas fa-search font-14 mg-l-1"></i>
        </el-input>
      </el-form-item>
    </el-col>

    <el-col
      :span="24"
      :sm="12"
      :md="6"
      :lg="6"
      v-if="role == 80 && typePage == 'clients'"
    >
      <el-form-item label="Audit cycle">
        <el-select
          clearable
          v-model="filter.auditCycle"
          placeholder="Select Audit cycle"
          @change="$emit('searchMasterData')"
        >
          <el-option
            v-for="item in optionsAuditCycle"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-col>
    <el-col :span="24" :sm="12" :md="6" :lg="6" v-if="typePage != 'admin'">
      <el-form-item label="Type">
        <el-select
          clearable
          v-model="filter.companyType"
          placeholder="Select Type"
          @change="$emit('selectOptionType', filter.companyType)"
        >
          <el-option
            v-for="item in typeMaster"
            :key="item.typeNum"
            :label="item.typeName"
            :value="item.typeNum"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-col>
    <el-col :span="24" :sm="12" :md="6" :lg="6" v-if="typePage != 'admin'">
      <el-form-item label="Company">
        <el-select
          clearable
          v-model="filter.company"
          placeholder="Select Company"
          :disabled="
            filter.companyType == 1 || !filter.companyType ? true : false
          "
          @change="$emit('selectOptionCompany', filter.company)"
        >
          <template slot="empty">
            <p class="el-select-dropdown__empty">ไม่มีข้อมูล</p>
          </template>
          <el-option
            v-for="item in optionsCompany"
            :key="item.companyNum"
            :label="item.companyName"
            :value="item.companyNum"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-col>
    <el-col :span="24" :sm="12" :md="6" :lg="6" v-if="typePage != 'admin'">
      <el-form-item label="Standard">
        <el-select
          clearable
          v-model="filter.standard"
          placeholder="Select Standard"
          :disabled="optionsStandard.length == 0 ? true : false"
          @change="$emit('searchMasterData')"
        >
          <template slot="empty">
            <p class="el-select-dropdown__empty">ไม่มีข้อมูล</p>
          </template>
          <el-option
            v-for="item in optionsStandard"
            :key="item.standardNum"
            :label="item.standardName"
            :value="item.standardNum"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-col>
    <el-col
      :span="24"
      :sm="12"
      :md="6"
      :lg="6"
      v-if="role == 80 && typePage != 'clients'"
    >
      <el-form-item label="Status">
        <el-select
          clearable
          v-model="filter.status"
          placeholder="Select Status"
          @change="$emit('searchMasterData')"
        >
          <template slot="empty">
            <p class="el-select-dropdown__empty">ไม่มีข้อมูล</p>
          </template>
          <el-option
            v-for="item in optionsStatus"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-col>
  </div>
</template>
<script>
export default {
  props: {
    filter: {
      type: Object,
      default: () => {},
    },
    typeMaster: {
      type: Array,
      default: [],
    },
    optionsCompany: {
      type: Array,
      default: [],
    },
    optionsStandard: {
      type: Array,
      default: [],
    },
    optionsStatus: {
      type: Array,
      default: [],
    },
    optionsAuditCycle: {
      type: Array,
      default: [],
    },
    typePage: {
      type: String,
      default: "",
    },
    role: {
      type: Number,
      default: 0,
    },
  },
};
</script>
